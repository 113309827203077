import { environment } from '../environments/environment';
export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{
					title: 'Home',
					root: true,
					icon: 'dashboard',
					page: '/',
					permission: [

					],
					bullet: 'dot',
					data: {
						exact: true
					}
				},
				{
					title: 'Users',
					root: true,
					icon: 'group',
					page: '/users',
					permission: [
						environment.customKeys.roleAdmin,
						environment.customKeys.roleAgencyAdmin,
					],
					bullet: 'dot',
					data: {
						exact: true
					}
				},
				{
					title: 'Podcasts',
					root: true,
					icon: 'podcasts',
					page: '/podcasts',
					permission: [
					],
					bullet: 'dot',
					data: {
						exact: true
					}
				},
				{
					title: 'Alerts',
					root: true,
					icon: 'notifications',
					page: '/alerts',
					permission: [
						environment.customKeys.roleAdmin,
						environment.customKeys.roleAgencyAdmin,
					],
					bullet: 'dot',
					data: {
						exact: true
					}
				},
				{
					title: 'Community',
					root: true,
					icon: 'groups',
					page: '/community/channels/all',
					permission: [
						environment.customKeys.roleAgencyAdmin,
						environment.customKeys.roleAgent,
					],
					bullet: 'dot',
					data: {
						hasNotifications: true,
						exact: false,
						type: 'community'
					},
				},
				{
					title: 'Community',
					root: true,
					icon: 'groups',
					page: '/community/dashboard',
					permission: [
						environment.customKeys.roleAdmin,
					],
					bullet: 'dot',
					data: {
						hasNotifications: true,
						exact: false,
						type: 'community'
					},
				},
				{
					title: 'Resources',
					root: true,
					icon: 'article',
					page: '/resources',
					permission: [
						environment.customKeys.roleAdmin,
					],
					bullet: 'dot',
					data: {
						exact: true
					}
				},
				// {
				// 	title: 'Community',
				// 	root: true,
				// 	icon: 'groups',
				// 	// page: '/community',
				// 	permission: [
				// 		environment.customKeys.roleAgencyAdmin,
				// 		environment.customKeys.roleAgent,
				// 		environment.customKeys.roleAdmin,
				// 	],
				// 	bullet: 'dot',
				// 	data: {
				// 		hasNotifications: true,
				// 	},
				// 	submenu: [
				// 		{
				// 			title: '1-1 Chats',
				// 			page: '/community/private/channels/all',
				// 			data: 'private',
				// 			permission: [
				// 				environment.customKeys.roleAgencyAdmin,
				// 				environment.customKeys.roleAgent,
				// 				environment.customKeys.roleAdmin
				// 			],
				// 		},
				// 		{
				// 			title: 'Community Channels',
				// 			page: '/community/dashboard',
				// 			data: 'dashboard',
				// 			permission: [
				// 				environment.customKeys.roleAdmin
				// 			],
				// 		},
				// 		{
				// 			title: 'Community Channels',
				// 			page: '/community/channels/all',
				// 			data: 'community',
				// 			permission: [
				// 				environment.customKeys.roleAgencyAdmin,
				// 				environment.customKeys.roleAgent,
				// 			],
				// 		},
				// 	]
				// },
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
